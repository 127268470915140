import { graphql } from "gatsby";
import React from "react";
import { useEffect } from "react";
import Footer from "../components/footer/footer";
import Header from "../components/header/header";
import Seo from "../components/seo";

// markup
const PrivacyPolicyPage = ({ location, data }) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://app.termly.io/embed-policy.min.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <>
      <Seo
        title={"Privacy Policy"}
        description={""}
        pageSeo={data.wpPage}
        location={location}
      />
      <Header scrollToForm=""></Header>
      <main>
        <div className="container">
          <div
            name="termly-embed"
            data-id="5c2fecc0-b862-4203-9690-1aed47fc2c73"
            data-type="iframe"
          ></div>
        </div>
      </main>
      <Footer scrollToForm=""></Footer>
    </>
  );
};

export const query = graphql`
  query {
    wpPage(slug: { eq: "privacy-policy" }) {
      title
      id
      seo {
        metaKeywords
        metaDesc
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        title
        twitterDescription
        twitterTitle
        opengraphSiteName
        opengraphTitle
        opengraphUrl
        focuskw
        opengraphImage {
          localFile {
            url
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        twitterImage {
          localFile {
            url
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        opengraphType
      }
    }
  }
`;

export default PrivacyPolicyPage;
